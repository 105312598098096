<template>
	<v-container>
		<validation-observer ref="observer" v-slot="{ invalid }">
			<form @submit.prevent="submit">
				<v-card elevation="24">
					<v-card-title>{{ $t('reset.title') }}</v-card-title>
					<v-card-text>
						<validation-provider v-slot="{ errors }" name="username" rules="required|email">
							<v-text-field v-model="username" :error-messages="errors" :label="$t('reset.email')"></v-text-field>
						</validation-provider>
						<v-btn block :disabled="invalid" type="submit">{{$t('reset.btn_reset_password')}}</v-btn>
					</v-card-text>
				</v-card>
			</form>
		</validation-observer>
<v-dialog
      v-model="aalert"
      persistent
      width="auto"
    >
<v-card>
        <v-card-title class="text-h5">
          {{aalert_label}}
        </v-card-title>
        <v-card-text>{{aalert_text}}</v-card-text>
        <v-card-actions>
          <v-btn v-if="msg == 'NO JSON REQUEST'" color="green-darken-1" variant="text" @click="aalert = false;">OK</v-btn>
          <v-btn v-if="msg == 'RESET DONE'" color="green-darken-1" variant="text" @click="redirect_to_home();">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	</v-container>
</template>

<script>
import { i18n } from "@/i18n/i18n"
import ApiService from "@/services/api_service"

import {
	ValidationObserver,
	ValidationProvider,
	configure,
	extend,
	setInteractionMode
} from "vee-validate"

import {
	email,
	required
} from "vee-validate/dist/rules";

setInteractionMode("eager");

configure({
	defaultMessage: (field, values) => {
		return i18n.t(`validation.${values._rule_}`, values)
	}
})

extend("email", email)
extend("required", required)

export default {
	components: {
		ValidationProvider,
		ValidationObserver
	},
	data: () => ({
		username: "",
		errors: null,
		aalert: false,
		aalert_label: 'Reset hesla',
		aalert_text: 'Spracovávam požiadavku. Prosím čakajte.',
		msg: ''
	}),
	methods: {
		redirect_to_home() {
			this.$router.push('/')
		},
		submit() {
			this.$refs.observer.validate()
			if(this.errors == null) {
				this.aalert = true
				ApiService.post('/auth/reset', { username: this.username }).then(response => {
					this.msg = response.data.msg
				}).catch(error => {
					this.msg = error.response.data.msg
				}).finally(() => {
				switch(this.msg) {
					case 'NO JSON REQUEST':
						this.aalert_text = 'Nastala neznáma chyba. Požiadavku opakujte neskôr prosím.'
						break;
					case 'RESET DONE':
						this.aalert_text = 'Vaša požiadavka o reset hesla používateľkého konta ' + this.username + ' bola prijatá. Na e-mailovú adresu Vám budú doručené potrebné informácie.'
						break;
					default:
						break;
				}})
			}
		}
	}
};
</script>

<style scoped>
</style>
